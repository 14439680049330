import styled from 'styled-components'
import PrimaryButton from 'components/Generic/PrimaryButton'
import { ReactComponent as FacebookIcon } from 'assets/FacebookIcon.svg'
import { ReactComponent as InstagramIcon } from 'assets/InstagramIcon.svg'
import { ReactComponent as TwitterIcon } from 'assets/TwitterIcon.svg'
import { ReactComponent as LinkedInIcon } from 'assets/LinkedInIcon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/YoutubeIcon.svg'
import { devices } from 'helpers/devices'
import LogoFooter from 'assets/LogoFooter.png'
import LogoDigitaly from 'assets/LogoDigitaly.png'
import { axiosEmail, sendMail } from 'helpers/VariableAmbient'
import { AxiosError, AxiosResponse } from 'axios'
import {
  setGlobalMessage,
  setSuccessMessage,
  toggleGlobalLoading
} from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

const Container = styled.footer`
  background-color: ${props => props.theme.color_primary_dark};
`

const FlexTemplate = styled.div`
  padding: 1rem calc(${props => props.theme.margin_from_sides} + 1rem);
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0.1fr 1fr 1fr;
  grid-column-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    padding: 1rem ${props => props.theme.margin_from_sides_mobile};
    max-width: 500px;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem calc(${props => props.theme.margin_from_sides_mobile} + 1rem);
    max-width: 500px;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tabletLandscape} {
    display: grid;
    max-width: 1600px;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem calc(${props => props.theme.margin_from_sides} + 1rem);
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(2, 1fr) 0.1fr 1fr 1fr;
    padding: 1rem calc(${props => props.theme.margin_from_sides} + 1rem);
  }
`

const Logo = styled.img`
  height: auto;
  width: 300px;
  grid-column-start: 1;
  align-self: center;
  justify-self: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: none;
  }

  @media ${devices.desktop} {
    display: block;
  }
`

const InfoContainer = styled.div`
  padding: 1rem 0;
  grid-column-start: 2;

  @media ${devices.tabletLandscape} {
    grid-column-start: 1;
  }

  @media ${devices.desktop} {
    grid-column-start: 2;
  }
`

const SacContainer = styled.div`
  grid-column-start: 4;
  height: 100%;

  @media ${devices.tabletLandscape} {
    grid-column-start: 2;
  }

  @media ${devices.desktop} {
    grid-column-start: 4;
  }
`

const FaleContainer = styled.div`
  grid-column-start: 5;
  height: 100%;

  @media ${devices.tabletLandscape} {
    grid-column-start: 3;
  }

  @media ${devices.desktop} {
    grid-column-start: 5;
  }
`

const SacTextGroup = styled.div`
  height: 90%;
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: space-around;
`

const SacSocials = styled.div`
  margin: 0.4rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & svg {
    margin: 0 0.1rem;
    width: 40px;
    height: 40px;
  }
`

const Title = styled.h3`
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  width: max-content;
  color: ${props => props.theme.color_text2};

  &::before {
    content: '';
    position: absolute;
    top: 200%;
    height: 1px;
    width: 100%;
    max-width: 2.18rem;
    background: ${props => props.theme.color_secondary_main};
  }

  @media ${devices.phoneOnly} {
    text-align: center;
    &::before {
      display: none;
    }
  }

  @media ${devices.tabletPortrait} {
    text-align: center;
    &::before {
      display: none;
    }
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    &::before {
      display: block;
    }
  }
`

const Text = styled.p`
  margin-top: 0.5rem;
  color: ${props => props.theme.color_text2};
  font-size: 0.8rem;
`

const Link = styled.a<{
  display?: string
  color?: string
  font_weight?: string
}>`
  display: ${props => (props.display ? props.display : 'inline')};
  color: ${props => props.color};
  font-weight: ${props => props.font_weight};
  text-decoration: none;

  & svg {
    height: auto;
    width: 30px;
  }
`

const LinkSac = styled(Link)`
  position: relative;
  z-index: 1;
  font-size: 0.9rem;

  &::before {
    content: '';
    position: absolute;
    top: 95%;
    width: 100%;
    height: 1px;
    background: #0000ee;
    z-index: 2;
  }
`

const FooterDigitaly = styled.div`
  padding: 0.5rem ${props => props.theme.margin_from_sides};
  background: ${props => props.theme.gray2};
  display: flex;
  justify-content: center;

  & a {
    text-decoration: none;
  }

  & p {
    text-align: center;
    color: ${props => props.theme.white1};
    font-size: ${props => props.theme.font_small};
  }

  & img {
    height: 30px;
    width: auto;
    margin-left: 0.5rem;
  }

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margin_from_sides_mobile};
    & p {
      font-size: 0.6rem;
    }

    & img {
      height: 20px;
    }
  }
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem 0;

  & div {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 335px;
  }
`

const Button = styled(PrimaryButton)`
  background: ${props => props.theme.white1};
  color: ${props => props.theme.gray2};

  &:hover {
    background: ${props => props.theme.white1};
  }
`

const Input = styled.input<{ maxWidth?: string }>`
  font-family: 'Helvetica';
  padding: 0.3rem;
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  border-radius: 10px;
  max-width: ${props => props.maxWidth};
`

const Select = styled.select<{ maxWidth?: string }>`
  font-family: 'Helvetica';
  padding: 0.3rem;
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  border-radius: 10px;
  max-width: ${props => props.maxWidth};
`

const TextArea = styled.textarea<{ maxWidth?: string }>`
  font-family: 'Helvetica';
  padding: 0.3rem;
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  border-radius: 10px;
  max-width: ${props => props.maxWidth};
`

const Footer = (): JSX.Element => {
  const dispatch = useDispatch()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [direction, setDirection] = useState<string>('SAC TRB Pharma')
  const [city, setCity] = useState<string>('')
  const [uf, setUf] = useState<string>('AC')
  const [body, setBody] = useState<string>('')

  const handleSubmit = () => {
    dispatch(toggleGlobalLoading())
    const bodyMail = {
      emails: [sendMail],
      subject: direction,
      name: name,
      email: email,
      city: city,
      state: uf,
      text: body
    }

    axiosEmail
      .post(`/send`, bodyMail)
      .then((response: AxiosResponse) => {
        dispatch(setSuccessMessage('E-mail enviado!'))
        setName('')
        setEmail('')
        setDirection('SAC TRB Pharma')
        setCity('')
        setUf('')
        setBody('')
        handleResponseSubmit()
      })
      .catch((error: AxiosError) => {
        dispatch(
          setGlobalMessage({
            message: `Error: ${error.response?.status}`,
            type: 'ERROR'
          })
        )
      })
      .finally(() => {
        dispatch(toggleGlobalLoading())
      })
  }

  const handleResponseSubmit = () => {
    const bodyMail = {
      emails: [email],
      name: name,
      subject: direction
    }

    axiosEmail
      .post(`/response`, bodyMail)
      .then((response: AxiosResponse) => {})
      .catch((error: AxiosError) => {
        dispatch(
          setGlobalMessage({
            message: `Error: ${error.response?.status}`,
            type: 'ERROR'
          })
        )
      })
  }
  return (
    <>
      <Container className='Footer' id='6'>
        <FlexTemplate>
          <Logo
            src={LogoFooter}
            alt='Logotipo da TRB'
            width='320px'
            height='250px'
          />
          <InfoContainer>
            <Text>
              TRB Pharma Industria Química e Farmacêutica LTDA. Av. Giuseppina
              Vianelli Di Napoli, 1100 <br />
              Polo II de Alta Tecnologia - Barão Geraldo <br />
              Campinas - SP <br />
              CEP: 13086-903
            </Text>
            <Text>
              Tel.:
              <Link>0800 100 55 88</Link>
              <br />
              <Link>www.trbpharma.com.br</Link>
            </Text>
            <Text>
              Copyright &copy; 2021 TRB Pharma - Todos os Direitos Reservados
            </Text>
          </InfoContainer>
          <SacContainer>
            <Title>SAC</Title>
            <SacTextGroup>
              <div>
                <Text>
                  <Link href='tel:08001005588' color='#FFF' font_weight='bold'>
                    0800 100 55 88
                  </Link>
                </Text>
                <Text>
                  <LinkSac href='mailto:sac@trbpharma.com.br' color='#FFF'>
                    sac@trbpharma.com.br
                  </LinkSac>
                </Text>
                <Text>
                  <LinkSac
                    href='https://titulares.becompliance.com/webforms/a5021fce-e53b-4a4b-afbe-b804dd41eb95/6fb2e411-dc2f-4a9c-978f-2121e42bf59c'
                    color='#FFF'
                  >
                    LGPD - Atendimento ao Titular
                  </LinkSac>
                </Text>
                <Text>
                  <LinkSac
                    href='/codigo-etica-e-conduta.pdf'
                    download='codigo-etica-e-conduta.pdf'
                    color='#FFF'
                  >
                    Código de Ética e Conduta
                  </LinkSac>
                </Text>
                <Text>
                  <LinkSac
                    href='/guia-confidencialidade.pdf'
                    download='guia-confidencialidade.pdf'
                    color='#FFF'
                  >
                    Guia de Confidencialidade
                  </LinkSac>
                </Text>
                <Text>
                  <LinkSac
                    href='/politica-anticorrupcao-antisuborno.pdf'
                    download='politica-anticorrupcao-antisuborno.pdf'
                    color='#FFF'
                  >
                    Política Anticorrupção e Antissuborno
                  </LinkSac>
                </Text>
                <Text>
                  <LinkSac
                    href='/politica-prevencao-lavagem-dinheiro.pdf'
                    download='politica-prevencao-lavagem-dinheiro.pdf'
                    color='#FFF'
                  >
                    Política de Prevenção a Lavagem de Dinheiro
                  </LinkSac>
                </Text>
              </div>
              <div>
                <Text>Siga-nos nas redes sociais</Text>
                <SacSocials>
                  <Link href='https://www.facebook.com/trbpharma/'>
                    <FacebookIcon />
                  </Link>
                  <Link href='https://www.instagram.com/trb_pharma/?hl=pt-br'>
                    <InstagramIcon />
                  </Link>
                  <Link href='https://twitter.com/TRB_br'>
                    <TwitterIcon />
                  </Link>
                  <Link href='https://www.linkedin.com/company/trb-pharma/?viewAsMember=true'>
                    <LinkedInIcon />
                  </Link>
                  <Link href='https://www.youtube.com/channel/UCtO-5J4fIIdYIlbBwznbeaA'>
                    <YoutubeIcon />
                  </Link>
                </SacSocials>
              </div>
            </SacTextGroup>
          </SacContainer>
          <FaleContainer>
            <Title>Fale Conosco</Title>
            <ContactForm
              onSubmit={event => {
                event.preventDefault()
                handleSubmit()
              }}
              id='form-contact'
            >
              <Input
                maxWidth='335px'
                type='text'
                name='nome'
                id='nome'
                placeholder='Nome *'
                value={name}
                onChange={event => setName(event.target.value)}
                required
              />
              <Input
                maxWidth='335px'
                type='email'
                name='email'
                id='email'
                placeholder='E-mail *'
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
              />
              <Select
                maxWidth='335px'
                name='direction'
                id='direction'
                placeholder='Area de Contato *'
                value={direction}
                onChange={event => setDirection(event.target.value)}
              >
                <option value='SAC TRB Pharma'>SAC TRB PHARMA</option>
              </Select>
              <div>
                <Input
                  type='text'
                  name='cidade'
                  id='cidade'
                  placeholder='Cidade *'
                  value={city}
                  onChange={event => setCity(event.target.value)}
                  required
                />
                <Select
                  maxWidth='100px'
                  name='estado'
                  id='estado'
                  placeholder='Estado *'
                  value={uf}
                  onChange={event => setUf(event.target.value)}
                  required
                >
                  <option value='AC'>Acre</option>
                  <option value='AL'>Alagoas</option>
                  <option value='AP'>Amapá</option>
                  <option value='AM'>Amazonas</option>
                  <option value='BA'>Bahia</option>
                  <option value='CE'>Ceará</option>
                  <option value='DF'>Distrito Federal</option>
                  <option value='ES'>Espírito Santo</option>
                  <option value='GO'>Goiás</option>
                  <option value='MA'>Maranhão</option>
                  <option value='MT'>Mato Grosso</option>
                  <option value='MS'>Mato Grosso do Sul</option>
                  <option value='MG'>Minas Gerais</option>
                  <option value='PA'>Pará</option>
                  <option value='PB'>Paraíba</option>
                  <option value='PR'>Paraná</option>
                  <option value='PE'>Pernambuco</option>
                  <option value='PI'>Piauí</option>
                  <option value='RJ'>Rio de Janeiro</option>
                  <option value='RN'>Rio Grande do Norte</option>
                  <option value='RS'>Rio Grande do Sul</option>
                  <option value='RO'>Rondônia</option>
                  <option value='RR'>Roraima</option>
                  <option value='SC'>Santa Catarina</option>
                  <option value='SP'>São Paulo</option>
                  <option value='SE'>Sergipe</option>
                  <option value='TO'>Tocantins</option>
                </Select>
              </div>
              <TextArea
                maxWidth='335px'
                id='mensagem'
                name='mensagem'
                rows={6}
                cols={12}
                placeholder='Escreva sua mensagem aqui *'
                value={body}
                onChange={event => setBody(event.target.value)}
                required
              ></TextArea>
              <Button>Enviar Mensagem</Button>
            </ContactForm>
          </FaleContainer>
        </FlexTemplate>
      </Container>
      <FooterDigitaly>
        <p>© 2021 | Criado e desenvolvido por</p>
        <a
          href='https://digitaly.tech'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={LogoDigitaly} alt='Logo Tipo da Digitaly' />
        </a>
      </FooterDigitaly>
    </>
  )
}

export default Footer
